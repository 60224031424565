import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import index from '!!raw-loader!.';
import { Code } from 'gatsby-theme-docz/src/components/Code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "eslint-react-config"
    }}>{`Eslint React Config`}</h1>
    <p>{`WILD Eslint configuration for React based projects - .tsx and .jsx.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`To use it in your project, you need to install the peerDependencies of this package.
We recommend to use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/install-peerdeps"
      }}>{`install-peerdeps`}</a></p>
    <h3 {...{
      "id": "if-youre-using-npm-7"
    }}>{`If you're using npm >=7`}</h3>
    <p>{`peerDependencies should be installed by default`}</p>
    <h3 {...{
      "id": "if-youre-using-npm-5"
    }}>{`If you're using npm >=5`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx install-peerdeps @madebywild/eslint-config --dev
`}</code></pre>
    <h3 {...{
      "id": "if-youre-using-npm-5-1"
    }}>{`If you're using npm <5`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g install-peerdeps && install-peerdeps @madebywild/eslint-config --dev
`}</code></pre>
    <p>{`Then add this to a `}<inlineCode parentName="p">{`.eslintrc`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "extends": "@madebywild/eslint-config"
}
`}</code></pre>
    <h2 {...{
      "id": "rules"
    }}>{`Rules`}</h2>
    <Code className="javascript" mdxType="Code">
  {index}
    </Code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      